<template>
  <div class="content">
    <div class="row mt-5">
      <div class="col-md-12">
        <h3>RECHERCHE CONTACT</h3>
        <div class="card">
          <div class="card-body">
            <div class="col-md-12">
              <div class="row">
                <div class="col-xl-10 col-lg-12 col-12 order-xl-1 order-1">
                  <div class="row">
                    <base-input
                      label="Adresse Email ou Telephone"
                      class="col-md-6 mb-2"
                      name="email"
                      required
                      placeholder="ADRESSE EMAIL OU TELEPHONE"
                      v-model="email"
                      v-validate="{
                        required: true
                      }"
                       type="email"
                      :error="getError('email')"
                      addon-left-icon="tim-icons icon-single-02"
                      :disabled="$apollo.queries.searchContact.loading"
                    >
                    </base-input>



                  </div>

                  <div class="row">

                    <base-input label="L'origine de la demande" class=" col-md-6 mb-2" v-if="!disable_desabonner" >
                      <el-select
                        class="select-primary"
                        v-model="from_source"
                      >
                        <el-option class="select-primary" label="DPO" value="DPO"></el-option>
                        <el-option class="select-primary" label="Email" value="Email"></el-option>
                        <el-option class="select-primary" label="Formulaire Mongustave" value="Formulaire Mongustave"></el-option>
                        <el-option class="select-primary" label="Partenaires" value="Partenaires"></el-option>
                        <el-option class="select-primary" label="Teletech" value="Teletech"></el-option>
                        <el-option class="select-primary" label="Autre" value="Autre"></el-option>


                      </el-select>
                    </base-input>


                  </div>
                </div>
                <div class="col-xl-2 col-lg-12 col-12 order-xl-2 order-3">
                  <div class="row">
                    <base-button
                      v-if="$apollo.queries.searchContact.loading"
                      loading
                      class="btn btn-just-icon btn-default mt-4 d-block"
                      >{{ $t("buttons.BtnLoad").toUpperCase() }}...</base-button
                    >
                    <button
                      v-else
                      class="btn btn-just-icon btn-default mt-4 d-block"
                      @click="search"
                    >
                      {{ $t("buttons.BtnSearch").toUpperCase() }}
                    </button>

                    <base-button
                      :disabled="isButtonDisabpmmerDisabled" v-if="!disable_desabonner"
                      class="btn btn-just-icon btn-default mt-4 d-block d-none"
                      @click="desabonner()">
                        DÉSABONNER
                    </base-button>

                    <base-button
                      :disabled="isButtonDisabpmmerDisabled" v-if="!disable_desabonner"
                      class="btn btn-just-icon btn-default mt-4 d-block d-none"
                      @click="desabonneremail()">
                      DÉSABONNER AVEC EMAIL
                    </base-button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="card" v-if="!disable_desabonner && (!leadmarket.length && !mongustave.length && !datadeal.length && !sendinblue.length && !unsubscribe.length && !coreg.length )">
          <span slot="header" class="badge-pill badge-market tab-title" style="text-transform: uppercase;font-weight: bold;background-color: #750000;">
            <img src=" " alt="" />

          </span>
          <div class="card-body">
             <h4> N'EXISTE PAS DANS LES BASES DE DONNÉES </h4>
          </div>
        </div>

        <div class="card" v-if="leadmarket.length">
          <span slot="header" class="badge-pill badge-market tab-title" style="text-transform: uppercase;font-weight: bold;">
            <img src="/img/weedo-it-logo-blanc.png" alt="" />
            WEEDO MARKET
          </span>
          <div class="card-body">
            <el-table row-class-name="booking-table-rows" :data="leadmarket">
              <el-table-column
                prop='nom'
                label="nom"
                minWidth='120'
                show-overflow-tooltip
                sortable
              >
              </el-table-column>
              <el-table-column
                prop='prenom'
                label="prenom"
                minWidth='120'
                show-overflow-tooltip
                sortable
              >
              </el-table-column>
              <el-table-column
                prop='telephone'
                label="telephone"
                minWidth='130'
                show-overflow-tooltip
                sortable
              >
              </el-table-column>

              <el-table-column
                prop='cp'
                label="cp"
                minWidth='90'
                show-overflow-tooltip
                sortable
              >
              </el-table-column>
              <el-table-column
                prop='ville'
                label="ville"
                minWidth='100'
                show-overflow-tooltip
                sortable
              >
              </el-table-column>
              <el-table-column
                prop='profession'
                label="profession"
                minWidth='150'
                show-overflow-tooltip
                sortable
              >
              </el-table-column>
              <el-table-column
                prop='date_naissance'
                label="date naissance"
                minWidth='180'
                show-overflow-tooltip
                sortable
              >
              </el-table-column>
              <el-table-column
                prop='date_lead'
                label="Ajouté Le"
                minWidth='180'
                show-overflow-tooltip
                sortable
              >
              </el-table-column>
              <el-table-column
                prop='provenance'
                label="provenance"
                minWidth='400'
                show-overflow-tooltip
                sortable
              >
                <template scope="scope">
                  <label style="cursor: pointer;" @click="openEditeurInfo(scope.row)">
                    {{ scope.row.provenance }}
                  </label>
                </template>
              </el-table-column>
              <el-table-column
                prop='email'
                label="email"
                minWidth='200'
                show-overflow-tooltip
                sortable
              >
              </el-table-column>
              <el-table-column
                prop='traces'
                label="traces"
                minWidth='350'
                show-overflow-tooltip
                sortable
              >
                <template scope="scope">
                  <template v-for="trace in scope.row.traces.split(';')">
                    <label :key="trace">{{ trace }}</label><br :key="trace">
                  </template>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
        <div class="card" v-if="mongustave.length">
          <span slot="header" class="badge-pill badge-mongustave tab-title" style="text-transform: uppercase;font-weight: bold;">
            <img src="/img/ico-mongustave.png" alt="" />
            MONGUSTAVE
          </span>
          <div class="card-body">
            <el-table row-class-name="booking-table-rows" :data="mongustave">
              <el-table-column
                prop='nom'
                label="nom"
                minWidth='120'
                show-overflow-tooltip
                sortable
              >
              </el-table-column>
              <el-table-column
                prop='prenom'
                label="prenom"
                minWidth='120'
                show-overflow-tooltip
                sortable
              >
              </el-table-column>
              <el-table-column
                prop='telephone'
                label="telephone"
                minWidth='130'
                show-overflow-tooltip
                sortable
              >
              </el-table-column>

              <el-table-column
                prop='cp'
                label="cp"
                minWidth='90'
                show-overflow-tooltip
                sortable
              >
              </el-table-column>
              <el-table-column
                prop='ville'
                label="ville"
                minWidth='100'
                show-overflow-tooltip
                sortable
              >
              </el-table-column>
              <el-table-column
                prop='profession'
                label="profession"
                minWidth='150'
                show-overflow-tooltip
                sortable
              >
              </el-table-column>
              <el-table-column
                prop='date_naissance'
                label="date naissance"
                minWidth='180'
                show-overflow-tooltip
                sortable
              >
              </el-table-column>
              <el-table-column
                prop='date_lead'
                label="Ajouté Le"
                minWidth='180'
                show-overflow-tooltip
                sortable
              >
              </el-table-column>
              <el-table-column
                prop='provenance'
                label="provenance"
                minWidth='400'
                show-overflow-tooltip
                sortable
              >
                <template scope="scope">
                  <label style="cursor: pointer;" @click="openEditeurInfo(scope.row)">
                    {{ scope.row.provenance }}
                  </label>
                </template>
              </el-table-column>
              <el-table-column
                prop='email'
                label="email"
                minWidth='200'
                show-overflow-tooltip
                sortable
              >
              </el-table-column>
              <el-table-column
                prop='traces'
                label="traces"
                minWidth='350'
                show-overflow-tooltip
                sortable
              >
                <template scope="scope">
                  <template v-for="trace in scope.row.traces.split(';')">
                    <label :key="trace">{{ trace }}</label><br :key="trace">
                  </template>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
        <div class="card" v-if="datadeal.length">
          <span slot="header" class="badge-pill badge-warning tab-title" style="text-transform: uppercase;font-weight: bold;">
            <img src="/img/weedo-it-logo-blanc.png" alt="" />
            DATADEAL
          </span>
          <div class="card-body">
            <el-table row-class-name="booking-table-rows" :data="datadeal">
              <el-table-column
                prop='nom'
                label="nom"
                minWidth='120'
                show-overflow-tooltip
                sortable
              >
              </el-table-column>
              <el-table-column
                prop='prenom'
                label="prenom"
                minWidth='120'
                show-overflow-tooltip
                sortable
              >
              </el-table-column>
              <el-table-column
                prop='telephone'
                label="telephone"
                minWidth='130'
                show-overflow-tooltip
                sortable
              >
              </el-table-column>

              <el-table-column
                prop='cp'
                label="cp"
                minWidth='90'
                show-overflow-tooltip
                sortable
              >
              </el-table-column>
              <el-table-column
                prop='ville'
                label="ville"
                minWidth='100'
                show-overflow-tooltip
                sortable
              >
              </el-table-column>
              <el-table-column
                prop='profession'
                label="profession"
                minWidth='150'
                show-overflow-tooltip
                sortable
              >
              </el-table-column>
              <el-table-column
                prop='date_naissance'
                label="date naissance"
                minWidth='180'
                show-overflow-tooltip
                sortable
              >
              </el-table-column>
              <el-table-column
                prop='date_lead'
                label="Dernière importation "
                minWidth='180'
                show-overflow-tooltip
                sortable
              >
              </el-table-column>
              <el-table-column
                prop='provenance'
                label="provenance"
                minWidth='300'
                show-overflow-tooltip
                sortable
              >
                <template scope="scope">
                  <label style="cursor: pointer;" @click="openEditeurInfo(scope.row)">
                    {{ scope.row.provenance }}
                  </label>
                </template>
              </el-table-column>
              <el-table-column
                prop='email'
                label="email"
                minWidth='200'
                show-overflow-tooltip
                sortable
              >
              </el-table-column>
               <el-table-column
                prop='traces'
                label="traces"
                minWidth='250'
                show-overflow-tooltip
                sortable
              >
              </el-table-column>
            </el-table>
          </div>
        </div>
        <div class="card" v-if="sendinblue.length">
          <span slot="header" class="badge-pill badge-coreg tab-title" style="text-transform: uppercase;font-weight: bold;">
            <img src="/img/sendinblue.png" alt="" />
            SENDINBLUE
          </span>
          <div class="card-body">
            <el-table row-class-name="booking-table-rows" :data="sendinblue">
              <el-table-column
                prop='email'
                label="email"
                minWidth='200'
                show-overflow-tooltip
                sortable
              >
              </el-table-column>
              <el-table-column
                prop='nom'
                label="nom"
                minWidth='120'
                show-overflow-tooltip
                sortable
              >
              </el-table-column>
              <el-table-column
                prop='prenom'
                label="prenom"
                minWidth='120'
                show-overflow-tooltip
                sortable
              >
              </el-table-column>

              <el-table-column
                prop='provenance'
                label="provenance"
                minWidth='400'
                show-overflow-tooltip
                sortable
              >
                <template scope="scope">
                  <label style="cursor: pointer;" @click="openEditeurInfo(scope.row)">
                    {{ scope.row.provenance }}
                  </label>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
        <div class="card" v-if="unsubscribe.length">
          <span slot="header" class="badge-pill badge-danger tab-title" style="text-transform: uppercase;font-weight: bold;">
            <img src="/img/weedo-it-logo-blanc.png" alt="" />
            CONTACTS DÉSABONNÉS
          </span>
          <div class="card-body">
            <el-table row-class-name="booking-table-rows" :data="unsubscribe">

              <el-table-column
                prop='statut'
                label="Platform"

                show-overflow-tooltip
                sortable
              >
                <template scope="scope">
                  <div style="cursor: pointer;"  >
                    <div class="icon_platform"  >
                      <img v-if="scope.row.statut.includes('sendinblue')" src="/img/sendinblue.png" alt="" />
                      <img  style=" background: #fc7454;" v-if="scope.row.statut.includes('datadeal')" src="/img/weedo-it-logo-blanc.png" alt="" />
                      <img  style=" background: #2b3277;" v-if="scope.row.statut.includes('leadmarket')" src="/img/weedo-it-logo-blanc.png" alt="" />
                      <img  style=" background: #2b3277;" v-if="scope.row.statut.includes('mongustave')" src="/img/ico-mongustave.png" alt="" />
                      <img  style=" background: #28829f;" v-if="scope.row.statut.includes('coreg')" src="/img/weedo-it-logo-blanc.png" alt="" />
                       <i v-if="scope.row.statut.includes('mongustave-1')"   class="fas fa-heartbeat  "></i>
                       <i v-if="scope.row.statut.includes('mongustave-2')"   class="fas fa-paw   "></i>
                       <i v-if="scope.row.statut.includes('mongustave-3')"   class="fas fa-home  "></i>
                       <i v-if="scope.row.statut.includes('mongustave-4')"   class="   fas fa-car   "></i>
                       <i v-if="scope.row.statut.includes('mongustave-5')"   class=" tim-icons icon-bank"></i>
                       <i v-if="scope.row.statut.includes('mongustave-6')"   class=" fas fa-credit-card "></i>
                    </div>
                  </div>
                </template>
              </el-table-column>
              <el-table-column
                prop='nom'
                label="nom"
                minWidth='110'

                show-overflow-tooltip
                sortable
              >
                <template scope="scope">
                  <label style="cursor: pointer;"  >
                    {{ scope.row.nom }}  {{ scope.row.prenom }}
                    <br>
                    {{scope.row.telephone}}
                  </label>
                </template>
              </el-table-column>


              <el-table-column
                prop='cp'
                label="cp"
                minWidth='90'
                show-overflow-tooltip
                sortable
              >
              </el-table-column>
              <el-table-column
                prop='ville'
                label="ville"
                minWidth='100'
                show-overflow-tooltip
                sortable
              >
              </el-table-column>
              <el-table-column
                prop='profession'
                label="profession"
                minWidth='150'
                show-overflow-tooltip
                sortable
              >
              </el-table-column>
              <el-table-column
                prop='date_naissance'
                label="date naissance"
                minWidth='180'
                show-overflow-tooltip
                sortable
              >
              </el-table-column>
              <el-table-column
                prop='date_lead'
                label="Ajouté Le"
                minWidth='180'
                show-overflow-tooltip
                sortable
              >
              </el-table-column>
              <el-table-column
                prop='provenance'
                label="provenance"
                minWidth='300'
                show-overflow-tooltip
                sortable
              >
                <template scope="scope">
                  <label style="cursor: pointer;" @click="openEditeurInfo(scope.row)">
                    {{ scope.row.provenance }}
                  </label>
                </template>
              </el-table-column>
              <el-table-column
                prop='email'
                label="email"
                minWidth='200'
                show-overflow-tooltip
                sortable
              >
              </el-table-column>
               <el-table-column
                prop='traces'
                label="traces"
                minWidth='250'
                show-overflow-tooltip
                sortable
              >
              </el-table-column>

              <el-table-column type="expand">
                <template scope="scope">
                  <template v-if="scope.row.traces">
                    <p class="text-uppercase">
                      traces :
                    </p>
                    <label class="text-uppercase" v-html="scope.row.traces.replace(/;/g, '<br>')"></label>
                  </template>
                </template>
              </el-table-column>


            </el-table>
          </div>
        </div>
        <div class="card" v-if="coreg.length">
          <span  slot="header" class="badge-pill badge-coreg tab-title" style="text-transform: uppercase;font-weight: bold;background: #588bbd42;">
            <img src="/img/weedo-it-logo-blanc.png" alt="" />
            Coreg
          </span>
          <div class="card-body">
            <el-table row-class-name="booking-table-rows" :data="coreg">


              <el-table-column
                prop='nom'
                label="nom"

                show-overflow-tooltip
                sortable
              >
                <template scope="scope">
                  <p style="cursor: pointer;"  >
                    {{ scope.row.nom }}  {{ scope.row.prenom }}
                    <br>
                    {{scope.row.telephone}}
                  </p>
                </template>
              </el-table-column>



               <el-table-column
                prop='cp'
                label="cp"

                show-overflow-tooltip
                sortable
              >
              </el-table-column>
              <el-table-column
                prop='ville'
                label="ville"

                show-overflow-tooltip
                sortable
              >
              </el-table-column>

              <el-table-column
                prop='date_naissance'
                label="date naissance"

                show-overflow-tooltip
                sortable
              >
              </el-table-column>

              <el-table-column
                prop='date_lead'
                label="Ajouté Le"
                 show-overflow-tooltip
                sortable
              >
              </el-table-column>
              <el-table-column
                prop='provenance'
                label="provenance"

                show-overflow-tooltip
                sortable
              >
                <template scope="scope">
                  <label style="cursor: pointer;"  >
                    {{ scope.row.provenance }}
                  </label>
                </template>
              </el-table-column>

              <el-table-column type="expand">
                <template scope="scope">
                  <template v-if="scope.row.traces">
                    <p class="text-uppercase">
                      traces :
                    </p>
                    <label class="text-uppercase" v-html="scope.row.traces.replace(/;/g, '<br>')"></label>
                  </template>
                </template>
              </el-table-column>



            </el-table>
          </div>
        </div>

      </div>
    </div>
    <modal
      :show.sync="ShowInfoUser"
      footerClasses="justify-content-center"
      class="modal-listbase"
      body-classes="p-0"
    >
      <card type="secondary"
        header-classes="bg-white pb-6"
        body-classes="px-lg-10 py-lg-10"
        class="border-0 mb-0"
      >
        <base-button style="float: right;" @click.native="ShowInfoUser = false" class="like btn-link" type="danger" size="sm" icon>
          <i class="tim-icons icon-simple-remove"></i>
        </base-button>
        <h3>LES INFORMATIONS DE L'EDITEUR</h3>
        <template v-if="User">
          <div class="form-row">
            <label class="col-md-6 text-uppercase"><b>NOM : </b>{{ User.first_name }} </label>
            <label class="col-md-6 text-uppercase"><b>PRENOM : </b>{{ User.last_name }}</label>
          </div>
          <div class="form-row">
            <label class="col-md-6 text-uppercase"><b>SOCIÉTÉ :</b> {{ User.society }}</label>
            <label class="col-md-6 text-uppercase"><b>SECTEUR D'ACTIVITÉ : </b>{{ User.activity_area }}</label>
          </div>
          <div class="form-row">
            <label class="col-md-6 text-uppercase"><b>EMAIL : </b>{{ User.email }}</label>
            <label class="col-md-6 text-uppercase"><b>TELEPHONE : </b>{{ User.phone }}</label>
          </div>
        </template>
      </card>
    </modal>
  </div>
</template>

  <script>
  import { Select, Option } from "element-ui";
  import { Table, TableColumn } from "element-ui";
  import gql from "graphql-tag";
  import { Modal } from 'src/components'
  import Swal from 'sweetalert2'
  import { BaseRadio } from 'src/components/index';

export default {
  apollo: {
    searchContact: {
      client: "MARKET",
      query: gql`
        query ($email: String!) {
          searchContact(email : $email){
            source
            email
            nom
            prenom
            telephone
            civilite
            cp
            ville
            compteur_livraison
            profession
            date_naissance
            date_lead
            provenance
            traces
            statut
          }
        }
      `,
      variables() {
        return {
          email: this.email
        };
      },
      skip() {
        return this.SkipQuery;
      },
      before()
      {
        this.mongustave =   this.leadmarket =  this.coreg = this.sendinblue = this.datadeal =  this.unsubscribe = [];

        this.disable_desabonner = true;

      },
      result({ data, loader, networkStatus }) {
        this.mongustave = data.searchContact.filter(element  => { return element.source == "mongustave"})
        this.leadmarket = data.searchContact.filter(element  => { return element.source == "leadmarket"})
        this.sendinblue = data.searchContact.filter(element  => { return element.source == "sendinblue"})
        this.coreg = data.searchContact.filter(element  => { return element.source == "coreg"})
        this.datadeal = data.searchContact.filter(element  => { return element.source == "datadeal"})
        this.unsubscribe = data.searchContact.filter(element  => { return element.source == "unsubscribe"})

        this.disable_desabonner = false;



        this.SkipQuery = true;
      },
      fetchPolicy: "network-only",
      update: (data) => data.getStatisticsMyGustaveProducts,
    },
  },
  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Select.name]: Select,
    [Option.name]: Option,
    Modal , BaseRadio
  },
  data() {
    return {
      SkipQuery: true,
      disable_desabonner: true,
      email: "",
      from_source: "Autre",
      mongustave: [],
      leadmarket: [],
      sendinblue: [],
      coreg: [],
      datadeal: [],
      unsubscribe: [],
      ShowInfoUser: false,
      User: null
    };
  },
  computed: {
    isButtonDisabpmmerDisabled() {
      return  false;
   // return  this.disable_desabonner;
    },
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
  },
  methods: {
    async search() {
      let validate;
      await this.$validator.validateAll().then(isValid => {
        validate = isValid;
      });
      if (!validate)
        return;

      this.SkipQuery = false;
    },
    getError(fieldName) {
      return this.errors.first(fieldName);
    },

    desabonneremail()
    {
      this.$router.push({ name: 'VerifEmailConfirmation', params: { email: this.email , source : this.from_source } });

    },
    async desabonner() {

      if(!this.email  )
      {
        this.$notify({type: 'warning', message: "Invalid email" })

        return;
      }



      let result = await Swal.fire({
        title: 'ÊTES-VOUS SÛR?',
        text: "",
        width: 400,
        showCancelButton: true,
        confirmButtonColor: '#988fc3',
        cancelButtonColor: '#27293D',
        confirmButtonText: 'VALIDER',
        cancelButtonText: 'ANNULER'
      })

      if (!result.value)
        return

      this.disable_desabonner = true

      await this.$apollo.query({
        client: 'MARKET',
        query: gql `
          query ($email: String! , $from_source : String! ,  $send_emails : String!  ) {
            deleteContact(
              email : $email
              from_source : $from_source
              send_emails : $send_emails
            ){
              state
            }
          }
        `,
        variables: {
          email: this.email,
          from_source: this.from_source,
          send_emails: "NON",
        },
        }).then(response => {

          if (response.data.deleteContact.state) {
            this.$notify({type: 'success', message: "Le contact a été désabonné avec succès." })
            // this.email = ""
            this.leadmarket = this.mongustave = this.datadeal = this.sendinblue = this.coreg = []
            this.disable_desabonner = true
            this.search();
          }
          else {
            this.$notify({type: 'warning', message: this.$t("apiresponse.errordeleting") })
          }

        }).catch(error => {
          console.log(error)
        })
    },
    async openEditeurInfo(row) {
      this.User = null;

      if (!row.provenance)
        return false

      await this.$apollo.query({
        client: 'MARKET',
        query: gql `
          query ($provenance: String!) {
            getAffiliateInfo(provenance : $provenance){
            id
            first_name
            last_name
            society
            activity_area
            email
            phone
          }
        }`,
        fetchPolicy: 'network-only',
        variables: {
          provenance: row.provenance
        },
      })
      .then(data => {
        this.User = data.data.getAffiliateInfo
        this.ShowInfoUser = true
      })
      .catch(error => {
        console.log(error)
      })
    }
  },
};
</script>

<style>
.booking-table-rows {
  text-transform: uppercase;
}

.icon_platform img
{
  border-radius: 8px; padding: 1px;
  width: 20px; height: 20px;
}
.icon_platform i
{
  padding: 2px;
  line-height: 38px;

}
</style>
