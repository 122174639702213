<template>
  <div class="content">
    <div class="row mt-5">
      <div class="col-md-12">
        <h3>RECHERCHE CONTACT</h3>
        <div class="card">
          <div class="card-body">
            <div class="col-md-12">
              <div class="row">
                <div class="col-xl-10 col-lg-12 col-12 order-xl-1 order-1">
                  <div class="row">
                    <base-input
                      label="Adresse Email ou Telephone"
                      class="col-md-6 mb-2"
                      name="email"
                      required
                      placeholder="ADRESSE EMAIL OU TELEPHONE"
                      v-model="email"
                      v-validate="{
                        required: true
                      }"
                      type="email"
                      :disabled="true"
                      :error="getError('email')"
                      addon-left-icon="tim-icons icon-single-02"
                     >
                    </base-input>



                  </div>

                  <div class="row">

                    <base-input label="L'origine de la demande" class=" col-md-6 mb-2" v-if="!disable_desabonner" >
                      <el-select
                        class="select-primary"
                        v-model="from_source"
                      >
                        <el-option class="select-primary" label="DPO" value="DPO"></el-option>
                        <el-option class="select-primary" label="Email" value="Email"></el-option>
                        <el-option class="select-primary" label="Formulaire Mongustave" value="Formulaire Mongustave"></el-option>
                         <el-option class="select-primary" label="Partenaires" value="Partenaires"></el-option>
                         <el-option class="select-primary" label="Teletech" value="Teletech"></el-option>
                        <el-option class="select-primary" label="Autre" value="Autre"></el-option>

                      </el-select>
                    </base-input>


                  </div>
                </div>
                <div class="col-xl-2 col-lg-12 col-12 order-xl-2 order-3">
                  <div class="row">


                    <base-button
                      :disabled="isButtonDisabpmmerDisabled" v-if="!disable_desabonner"
                      class="btn btn-just-icon btn-default mt-4 d-block d-none"
                      @click="desabonner()">
                        DÉSABONNER
                    </base-button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="card"   v-loading="loading" >
          <span slot="header" class="badge-pill badge-market tab-title" style="text-transform: uppercase;font-weight: bold;background-color: #750000;">
            <img src=" " alt="" />

          </span>
          <div class="card-body">
            <h4  v-if="loading" > Chargement des données  .... </h4>


             <div v-if="email_view">
              <h4> Emails à envoyer a  internaute : </h4>

                  <div  class="row"     >

                   <div  class="col-md-12">

                     <div style="width: 100%;border: 1px solid;padding: 9px;height: auto;margin-top: 20px;" >
                       <div class="row"   >
                         <base-input
                           class="col-md-6 mb-2"
                           name="email"
                           required
                           disabled="true"
                           placeholder="ADRESSE EMAIL OU TELEPHONE"
                           v-model="email"
                           v-validate="{
                        required: true
                      }"
                           type="email"
                           :error="getError('email')"
                           addon-left-icon="tim-icons icon-single-02"
                         >
                         </base-input>


                         <div class="form-check mb-3  theCheckBoxWeedo form-check-inline" >
                           <label  class="form-check-label">

                             <input type="checkbox" v-model="checkedEmails" :value="email" class="form-check-input">

                             <span class="form-check-sign"></span>

                           </label>

                         </div>


                       </div>


                       <div  v-html="email_view" disabled="true">  </div>

                     </div>


                   </div>

                 </div>


            </div>

             <div v-if="arr_affilie.length || editors.length">
              <h4> Emails à envoyer aux affiliés : </h4>

                  <div  class="row"     >

                   <div   :key="affilie.email"  v-for=" (affilie)   in arr_affilie" class="col-lg-6">

                     <div style="width: 100%;border: 1px solid;padding: 9px;height: auto;margin-top: 20px;" >
                       <div class="row"   >
                         <base-input
                           class="col-md-6 mb-2"
                           name="email"
                           required
                           disabled="true"
                           placeholder="ADRESSE EMAIL OU TELEPHONE"
                           v-model="affilie.email"
                           v-validate="{
                        required: true
                      }"
                           type="email"
                           :error="getError('email')"
                           addon-left-icon="tim-icons icon-single-02"
                         >
                         </base-input>



                         <div class="form-check mb-3  theCheckBoxWeedoaff form-check-inline" >
                           <label  class="form-check-label">

                             <input type="checkbox" v-model="checkedEmails" :value="affilie.email" class="form-check-input">

                             <span class="form-check-sign"></span>

                           </label>

                         </div>
                       </div>


                       <div  v-html="affilie.view" disabled="true">  </div>

                     </div>


                   </div>
                   <div   :key="affilie.email"  v-for=" (affilie)   in editors" class="col-lg-6">

                     <div style="width: 100%;border: 1px solid;padding: 9px;height: auto;margin-top: 20px;" >
                       <div class="row"   >
                         <base-input
                           class="col-md-6 mb-2"
                           name="email"
                           required
                           disabled="true"
                           placeholder="ADRESSE EMAIL OU TELEPHONE"
                           v-model="affilie.email"
                           v-validate="{
                        required: true
                      }"
                           type="email"
                           :error="getError('email')"
                           addon-left-icon="tim-icons icon-single-02"
                         >
                         </base-input>

                         <div class="form-check mb-3  theCheckBoxWeedoaff form-check-inline" >
                           <label  class="form-check-label">

                             <input type="checkbox" v-model="checkedEmails" :value="affilie.email" class="form-check-input">

                             <span class="form-check-sign"></span>

                           </label>

                         </div>

                       </div>


                       <div  v-html="affilie.view" disabled="true">  </div>

                     </div>


                   </div>

                 </div>


            </div>

            <div style="padding-top: 10px" v-if="clients.length || announcers.length">
              <h4> Emails à envoyer aux clients : </h4>

              <div class="row">
                <div class="col-md-9"  style="width: 100%;border: 1px solid;padding: 9px;height: auto;margin-top: 20px;">
                  <div class="row" >
                    <base-input
                      class="col-md-6 mb-2"
                      name="email"
                      required
                      disabled="true"
                      placeholder="ADRESSE EMAIL OU TELEPHONE"
                      :value="clientEmails"
                      v-validate="{
                        required: true
                      }"
                      type="email"
                      addon-left-icon="tim-icons icon-single-02"
                    >
                    </base-input>



                  </div>

                  <div style="border-bottom: 1px; color: white; background: white; margin-top: 5px; margin-bottom:  5px ; width: 100%"></div>

                  <div v-if="clients.length"  v-html="clients[0].view" class="unclickable" disabled="true">  </div>
                  <div v-else-if="announcers.length"  v-html="announcers[0].view" class="unclickable" disabled="true">  </div>

                </div>

                <div class="col-md-3"  style="width: 100%;border: 1px solid;padding: 9px;height: auto;margin-top: 20px;">

                  <div class="form-check mb-3  " :key="client.email"  v-for=" (client)   in clients" >
                    <label  class="form-check-label">

                      <input type="checkbox" v-model="checkedEmails" :value="client.email" class="form-check-input">

                      <span class="form-check-sign">{{ client.email }}</span>

                    </label>

                  </div>

                  <div class="form-check mb-3  " :key="client.email"  v-for=" (client)   in announcers" >
                    <label  class="form-check-label">

                      <input type="checkbox" v-model="checkedEmails" :value="client.email" class="form-check-input">

                      <span class="form-check-sign">{{ client.email }}</span>

                    </label>

                  </div>

                </div>
              </div>


            </div>

          </div>
        </div>


        <div>

        </div>


      </div>
    </div>
    <modal
      :show.sync="ShowInfoUser"
      footerClasses="justify-content-center"
      class="modal-listbase"
      body-classes="p-0"
    >
      <card type="secondary"
        header-classes="bg-white pb-6"
        body-classes="px-lg-10 py-lg-10"
        class="border-0 mb-0"
      >
        <base-button style="float: right;" @click.native="ShowInfoUser = false" class="like btn-link" type="danger" size="sm" icon>
          <i class="tim-icons icon-simple-remove"></i>
        </base-button>
        <h3>LES INFORMATIONS DE L'EDITEUR</h3>
        <template v-if="User">
          <div class="form-row">
            <label class="col-md-6 text-uppercase"><b>NOM : </b>{{ User.first_name }} </label>
            <label class="col-md-6 text-uppercase"><b>PRENOM : </b>{{ User.last_name }}</label>
          </div>
          <div class="form-row">
            <label class="col-md-6 text-uppercase"><b>SOCIÉTÉ :</b> {{ User.society }}</label>
            <label class="col-md-6 text-uppercase"><b>SECTEUR D'ACTIVITÉ : </b>{{ User.activity_area }}</label>
          </div>
          <div class="form-row">
            <label class="col-md-6 text-uppercase"><b>EMAIL : </b>{{ User.email }}</label>
            <label class="col-md-6 text-uppercase"><b>TELEPHONE : </b>{{ User.phone }}</label>
          </div>
        </template>
      </card>
    </modal>
  </div>
</template>

  <script>
  import { Select, Option } from "element-ui";
  import { Table, TableColumn } from "element-ui";
  import gql from "graphql-tag";
  import { Modal } from 'src/components'
  import Swal from 'sweetalert2'
  import { BaseRadio } from 'src/components/index';
  import Axios from "axios";

export default {
   components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Select.name]: Select,
    [Option.name]: Option,
    Modal , BaseRadio
  },
  data() {
    return {
      SkipQuery: true,
      disable_desabonner: true,
      loading: true,
      email: "",
      arr_affilie: [],
      editors: [],
      checkedEmails: [],
      clients: [],
      announcers: [],
      from_source: "Autre",
      mongustave: [],
      leadmarket: [],
      sendinblue: [],
      coreg: [],
      datadeal: [],
      unsubscribe: [],
      ShowInfoUser: false,
      email_view: false,
      User: null
    };
  },
  mounted() {

    this.email =  this.$route.params.email;
    this.from_source =  this.$route.params.source;
    this.searchEmails();
  },
  computed: {

    clientEmails()
    {
      let stringtext = this.clients.map((obj) => obj.email).join('; ');
      stringtext = stringtext+'; '+ this.announcers.map((obj) => obj.email).join('; ');
        return stringtext;

    },
    isButtonDisabpmmerDisabled() {
      return  false;
   // return  this.disable_desabonner;
    },
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
  },
  methods: {
    fillcheckedEmails() {
      this.checkedEmails = [];
      this.checkedEmails.push( this.email);


      this.arr_affilie.forEach((obj) => {
        this.checkedEmails.push( obj.email);
      });

      this.editors.forEach((obj) => {
        this.checkedEmails.push( obj.email);
      });

      this.announcers.forEach((obj) => {
        this.checkedEmails.push( obj.email);
      });

      this.clients.forEach((obj) => {
        this.checkedEmails.push( obj.email);
      });
    },
  async   searchEmails(){
      console.log('starting search ...');

    const url_api = "https://apileadmarket.wee-do-it.net/api/desabonnemen_simulation_list/"+this.email+"/"+this.from_source;
    let accessToken = localStorage.getItem("token_jwt");
    let formData = new FormData()
    console.log(accessToken)


    this.loading = true;
    this.disable_desabonner = false;
    this.axios.get(
      url_api,
      {
        headers: {
          "Content-Type": "application/json",
          "authorization": `Bearer ${accessToken}`,
        }
      }
    ).then((response) =>{
      this.loading = false;
      this.disable_desabonner = false;
      this.arr_affilie = response.data.data.$arr_affilie;
      this.clients = response.data.data.clients;
       this.email_view = response.data.data.email_view;
      this.editors = response.data.data.editors;
      this.announcers = response.data.data.announcers;
      console.log(response.data);
      this.fillcheckedEmails();
    })



    },
    async search() {
      let validate;
      await this.$validator.validateAll().then(isValid => {
        validate = isValid;
      });
      if (!validate)
        return;

      this.SkipQuery = false;
    },
    getError(fieldName) {
      return this.errors.first(fieldName);
    },
    async desabonner() {

      if(!this.email  )
      {
        this.$notify({type: 'warning', message: "Invalid email" })
        return;
      }

      let result = await Swal.fire({
        title: 'ÊTES-VOUS SÛR?',
        text: "",
        width: 400,
        showCancelButton: true,
        confirmButtonColor: '#988fc3',
        cancelButtonColor: '#27293D',
        confirmButtonText: 'VALIDER',
        cancelButtonText: 'ANNULER'
      })

      if (!result.value)
        return ;

      this.disable_desabonner = true

      await this.$apollo.query({
        client: 'MARKET',
        query: gql `
          query ($email: String! , $from_source : String! ,  $send_emails : String! , $simulation : String! , $checked_emails : [String] ) {
            deleteContact(
              email : $email
              from_source : $from_source
              send_emails : $send_emails
              simulation : $simulation
              checked_emails : $checked_emails
             ){
              state
            }
          }
        `,
        variables: {
          email: this.email,
          from_source: this.from_source,
          send_emails: "1send_emails",
          simulation: "NO",
          checked_emails: this.checkedEmails,
        },
        }).then(response => {

          if (response.data.deleteContact.state) {
            this.$notify({type: 'success', message: "Le contact a été désabonné avec succès." })
            // this.email = ""
            this.leadmarket = this.mongustave = this.datadeal = this.sendinblue = this.coreg = []
            this.disable_desabonner = true
             this.$router.push({ name: 'Leads Desabonners'})

          }
          else {
            this.$notify({type: 'warning', message: this.$t("apiresponse.errordeleting") })
          }

        }).catch(error => {
          console.log(error)
        })
    },
    async openEditeurInfo(row) {
      this.User = null;

      if (!row.provenance)
        return false

      await this.$apollo.query({
        client: 'MARKET',
        query: gql `
          query ($provenance: String!) {
            getAffiliateInfo(provenance : $provenance){
            id
            first_name
            last_name
            society
            activity_area
            email
            phone
          }
        }`,
        fetchPolicy: 'network-only',
        variables: {
          provenance: row.provenance
        },
      })
      .then(data => {
        this.User = data.data.getAffiliateInfo
        this.ShowInfoUser = true
      })
      .catch(error => {
        console.log(error)
      })
    }
  },
};
</script>

<style>
.booking-table-rows {
  text-transform: uppercase;
}

.icon_platform img
{
  border-radius: 8px; padding: 1px;
  width: 20px; height: 20px;
}
.icon_platform i
{
  padding: 2px;
  line-height: 38px;

}

td p span
{
  color:  white !important;
}

.unclickable {
  pointer-events: none;
}
.theCheckBoxWeedo
{

    position: absolute;
    left: 97%;
    /* right: auto; */

}


.theCheckBoxWeedoaff
{

  position: absolute;
  left: 95%;
  /* right: auto; */

}
</style>
