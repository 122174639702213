<template>
  <div class="content">
    <h3>VUE D'ENSEMBLE</h3>
    <div class="col-lg-12">
      <div class="card card-chart" data="orange">
        <div class="card-header">
          <div class="col-sm-6 text-left">
            <h2 class="card-title">NOMBRE DE DÉSABONNEMENT</h2>
            <h5 class="card-category" style="color: #000">PAR MOIS</h5>
          </div>
        </div>
        <div class="card-body">
          <base-button
            v-if="$apollo.queries.getUnsubscribeContacts.loading"
            style="
              position: absolute;
              top: 60%;
              left: 50%;
              -moz-transform: translateX(-50%) translateY(-50%);
              -webkit-transform: translateX(-50%) translateY(-50%);
              transform: translateX(-50%) translateY(-50%);
            "
            loading
            type="default"
          >
          </base-button>
          <div class="chart-area">
            <bar-chart
              v-if="!$apollo.queries.getUnsubscribeContacts.loading"
              style="height: 100%"
              :chart-data="barChartCa.chartData"
              :extra-options="barChartCa.extraOptions"
            >
            </bar-chart>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-xl-1 col-sm-6">
        <div class="form-group">
          <div class="mb-0">
            <div class="dropdown">
              <select
                class="btn btn-primary dropdown-toggle"
                v-model="selectedMonth"
                @change="filterByMonth"
              >
                <option
                  class="dropdown-item"
                  value="Tous"
                  style="background-color: #333; color: #000"
                >
                  Tous
                </option>
                <option
                  class="dropdown-item"
                  v-for="monthYear in TwelveMonths()"
                  :key="monthYear"
                  :value="monthYear"
                  style="background-color: #f0f0f0; color: #000"
                >
                  {{ monthYear }}
                </option>
              </select>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-4">
        <div class="card">
          <span
            slot="header"
            class="badge-pill badge-warning tab-title"
            style="font-weight: bold"
          >
            DEMANDE PAR SOURCE
          </span>
          <br />
          <base-button
            class="form-control"
            v-if="$apollo.queries.getUnsubscribeContacts.loading"
            style="
              position: absolute;
              top: 60%;
              left: 50%;
              -moz-transform: translateX(-50%) translateY(-50%);
              -webkit-transform: translateX(-50%) translateY(-50%);
              transform: translateX(-50%) translateY(-50%);
            "
            loading
            type="default"
          >
          </base-button>

          <PieChart
            :chartData="dataChartVolume_by_source || {}"
            :height="isMobile() ? 300 : 200"
            v-if="!$apollo.queries.getUnsubscribeContacts.loading"
            :extraOptions="pieChart.extraOptions"
          />
        </div>
      </div>
      <div class="col-md-4">
        <div class="card">
          <span
            slot="header"
            class="badge-pill badge-warning tab-title"
            style="font-weight: bold"
          >
            STATUT DE LA DEMANDE
          </span>
          <br />
          <base-button
            class="form-control"
            v-if="$apollo.queries.getUnsubscribeContacts.loading"
            style="
              position: absolute;
              top: 60%;
              left: 50%;
              -moz-transform: translateX(-50%) translateY(-50%);
              -webkit-transform: translateX(-50%) translateY(-50%);
              transform: translateX(-50%) translateY(-50%);
            "
            loading
            type="default"
          >
          </base-button>
          <PieChart
            :chartData="dataChartVolume_by_demandes_status || {}"
            :height="isMobile() ? 400 : 200"
            v-if="!$apollo.queries.getUnsubscribeContacts.loading"
            :extraOptions="pieChart.extraOptions"
          />
        </div>
      </div>
      <div class="col-md-4">
        <div class="card">
          <span
            slot="header"
            class="badge-pill badge-warning tab-title"
            style="font-weight: bold"
          >
            PERSONNE AYANT TRAITER LA DEMANDE
          </span>
          <br />
          <base-button
            class="form-control"
            v-if="$apollo.queries.getUnsubscribeContacts.loading"
            style="
              position: absolute;
              top: 60%;
              left: 50%;
              -moz-transform: translateX(-50%) translateY(-50%);
              -webkit-transform: translateX(-50%) translateY(-50%);
              transform: translateX(-50%) translateY(-50%);
            "
            loading
            type="default"
          >
          </base-button>
          <PieChart
            :chartData="dataChartVolume_by_user_traited_demande || {}"
            :height="isMobile() ? 400 : 200"
            v-if="!$apollo.queries.getUnsubscribeContacts.loading"
            :extraOptions="pieChart.extraOptions"
          />
        </div>
      </div>
    </div>
    <!--
    <div class="row">
      <div class="col-md-12">
        <div class="card">
          <div class="card card-chart">
            <div class="card-header">
              <div class="col-sm-12 text-left">
                <h2 class="card-title">Nombre des désabonnements</h2>
              </div>
              <div class="row">
                <div class="col-md-4 float-left">
                  <base-input>
                    <el-select
                      class="select-primary pagination-select"
                      v-model="vartest"
                      placeholder="Sélectionner une option"
                    >
                    <el-option
                        class="select-primary"
                        label="Tous"
                        value="all"
                      ></el-option>
                      <el-option
                        class="select-primary"
                        label="Volume Élevé"
                        value="top"
                      ></el-option>
                      <el-option
                        class="select-primary"
                        label="Volume Faible"
                        value="down"
                      ></el-option>
                    </el-select>
                  </base-input>
                </div>
                <div class="col-md-8 float-right">
                  <base-input class="col-xl-6 col-sm-4 float-right">
                    <el-select
                      class="select-primary pagination-select"
                      v-model="pagination.perPage"
                      placeholder="Per page"
                    >
                      <el-option
                        class="select-primary"
                        v-for="item in pagination.perPageOptions"
                        :key="item"
                        :label="item"
                        :value="item"
                      ></el-option>
                    </el-select>
                  </base-input>
                </div>
              </div>

              <el-table
                :data="computedProvenancedata"
                v-loading="getUnsubscribeContacts.loading"
                row-class-name="booking-table-rows"
                class="customtable_cmd"
                style="width: 100%"
              >
                <el-table-column prop="provenance" label="Provenance"></el-table-column>
                <el-table-column prop="volume" label="Volume"></el-table-column>
              </el-table>
              <div
                class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
              >
                <div class=""></div>
                <base-pagination
                  class="pagination-no-border"
                  v-model="pagination.currentPage"
                  type="warning"
                  :per-page="pagination.perPage"
                  :total="total"
                  :per-page-options="pagination.perPageOptions"
                >
                </base-pagination>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    -->
  </div>
</template>

<script>
import BarChart from "@/components/Charts/BarChart";
import { Table, TableColumn } from "element-ui";
import { BasePagination } from "src/components";
import { Select, Option } from "element-ui";
import PieChart from "src/components/Charts/PieChart";
import * as chartConfigs from "@/components/Charts/config";
import gql from "graphql-tag";
import moment from "moment";
moment.locale(localStorage.getItem("lang") == "en" ? "en" : "fr");

export default {
  apollo: {
    getUnsubscribeContacts: {
      client: "MARKET",
      query: gql`
        query {
          getUnsubscribeContacts {
            id
            demande_status
            source
            from_source
            demande_date
            provenance
            user_traited_demande
          }
        }
      `,

      result({ data }) {
        if (!data || !data.getUnsubscribeContacts) {
          console.warn("No unsubscribe contacts data received.");
          return;
        }
        const cumulativeUnsubscrip = {};
        const lastTwelveMonths = this.TwelveMonths();
        const cumulativeResult = [];

        lastTwelveMonths.forEach((month) => {
          cumulativeUnsubscrip[month] = 0;
        });

        //console.log("apollo", data.getUnsubscribeContacts);
        this.provenancedata1 = data.getUnsubscribeContacts;

        data.getUnsubscribeContacts.forEach((contact) => {
          const monthYear = moment(contact.demande_date).format("MM/YYYY");
          if (cumulativeUnsubscrip.hasOwnProperty(monthYear)) {
            cumulativeUnsubscrip[monthYear]++;
          }
        });
        lastTwelveMonths.forEach((month) => {
          cumulativeResult.push({
            month: moment(month, "MM/YYYY").format("MM/YYYY"),
            count: cumulativeUnsubscrip[month],
          });
        });

        const labels = cumulativeResult.map((item) => item.month);

        const dataValues = cumulativeResult.map((item) => item.count);

        this.barChartCa.chartData.labels = labels;
        this.barChartCa.chartData.datasets[0].data = dataValues;

        const userTraitementData = data.getUnsubscribeContacts.filter((contact) => {
          return contact.user_traited_demande;
        });
        // Dans votre méthode result({ data }) après avoir récupéré les données
        const volumeByProvenance = {};

        data.getUnsubscribeContacts.forEach((contact) => {
          const provenance = contact.provenance;
          volumeByProvenance[provenance] = (volumeByProvenance[provenance] || 0) + 1;
        });

        const provenanceLabels = Object.keys(volumeByProvenance);
        const provenanceVolumes = Object.values(volumeByProvenance);

        // Assigner ces données à votre graphique de barres
        this.barChartProvenance.chartData.labels = provenanceLabels;
        this.barChartProvenance.chartData.datasets[0].data = provenanceVolumes;

        // Préparer provenancedata pour la table Element UI
        this.provenancedata = provenanceLabels.map((provenance, index) => ({
          provenance: provenance,
          volume: provenanceVolumes[index],
        }));
        this.provenancedata1 = provenanceLabels.map((provenance, index) => ({
          provenance: provenance,
          volume: provenanceVolumes[index],
        }));

        const volumeByusertraitedemande = {};

        userTraitementData.forEach((contact) => {
          const user_traited_demande = contact.user_traited_demande;
          volumeByusertraitedemande[user_traited_demande] =
            (volumeByusertraitedemande[user_traited_demande] || 0) + 1;
        });

        const user_traited_demande_labels = Object.keys(volumeByusertraitedemande);
        const user_traited_demande_volume = Object.values(volumeByusertraitedemande);

        const volumeByStatus = {};
        const volumeBySource = {};
        const twelveMonths = this.TwelveMonths();
        const contactsLastTwelveMonths = data.getUnsubscribeContacts.filter((contact) => {
          const contactDate = new Date(contact.demande_date);
          return twelveMonths.some((month) => {
            const [monthNumber, year] = month.split("/");
            return (
              contactDate.getMonth() + 1 === parseInt(monthNumber) &&
              contactDate.getFullYear() === parseInt(year)
            );
          });
        });

        contactsLastTwelveMonths.forEach((contact) => {
          const status = contact.demande_status.toUpperCase();

          const source = contact.from_source.toUpperCase();

          volumeByStatus[status] = (volumeByStatus[status] || 0) + 1;
          volumeBySource[source] = (volumeBySource[source] || 0) + 1;
        });

        this.datatwelveMonthsStatus = contactsLastTwelveMonths;
        this.datatwelveMonthsSource = contactsLastTwelveMonths;
        this.datatwelveMonthsUserTraitement = contactsLastTwelveMonths;

        const statuses = Object.keys(volumeByStatus);
        const statusVolumes = statuses.map((status) => volumeByStatus[status]);
        const sources = Object.keys(volumeBySource);
        const volumes = sources.map((source) => volumeBySource[source]);

        this.dataChartVolume_by_demandes_status = {
          labels: statuses,
          datasets: [
            {
              label: "",
              pointRadius: 0,
              pointHoverRadius: 0,
              backgroundColor: [
                "#1B61F7",
                "#DD4E01",
                "#088A08",
                "#ff0040",
                "#772e7f",
                "#fa7a47",
                "#9143df",
                "#dde02e",
                "#75ff42",
                "#FF0000",
                "#000",
                "#ffffa3",
                "#ffa6a3",
                "#b3908f",
                "#47fa80",
                "#601010",
                "#01FF70",
                "#85144b",
                "#F012BE",
                "#3D9970",
                "#111111",
                "#AAAAAA', '#FFDADA",
              ],
              borderWidth: 0,
              data: statusVolumes,
            },
          ],
        };

        this.dataChartVolume_by_source = {
          labels: sources,
          datasets: [
            {
              label: "",
              pointRadius: 0,
              pointHoverRadius: 0,
              backgroundColor: [
                "#1B61F7",
                "#DD4E01",
                "#088A08",
                "#ff0040",
                "#772e7f",
                "#fa7a47",
                "#9143df",
                "#dde02e",
                "#75ff42",
                "#FF0000",
                "#000",
                "#ffffa3",
                "#ffa6a3",
                "#b3908f",
                "#47fa80",
                "#601010",
                "#01FF70",
                "#85144b",
                "#F012BE",
                "#3D9970",
                "#111111",
                "#AAAAAA', '#FFDADA",
              ],
              borderWidth: 0,
              data: volumes,
            },
          ],
        };
        this.dataChartVolume_by_user_traited_demande = {
          labels: user_traited_demande_labels,
          datasets: [
            {
              label: "",
              pointRadius: 0,
              pointHoverRadius: 0,
              backgroundColor: [
                "#1B61F7",
                "#DD4E01",
                "#088A08",
                "#ff0040",
                "#772e7f",
                "#fa7a47",
                "#9143df",
                "#dde02e",
                "#75ff42",
                "#FF0000",
                "#000",
                "#ffffa3",
                "#ffa6a3",
                "#b3908f",
                "#47fa80",
                "#601010",
                "#01FF70",
                "#85144b",
                "#F012BE",
                "#3D9970",
                "#111111",
                "#AAAAAA",
                "#FFDADA",
              ],
              borderWidth: 0,
              data: user_traited_demande_volume,
            },
          ],
        };
      },
    },
  },
  components: {
    BarChart,
    PieChart,
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    BasePagination,
  },

  data() {
    return {
      provenancedata: [],
      originalProvenancedata: [],
      vartest: "all",
      barChartCa: {
        chartData: {
          labels: [],
          datasets: [
            {
              label: "DÉSABONNEMENT",
              fill: true,
              backgroundColor: "#DD4E01",
              hoverBackgroundColor: "#DD4E01",
              borderColor: "#DD4E01",
              borderWidth: 2,
              borderDash: [],

              borderDashOffset: 0,
              data: [],
            },
          ],
        },
        extraOptions: {
          ...chartConfigs.barChartOptionsGradient,
          legend: {
            display: true,
            position: "bottom",
            align: "center",
            labels: {
              boxWidth: 15,
              fontSize: 12,
            },
          },
          tooltips: {
            backgroundColor: "#f5f5f5",
            titleFontColor: "#333",
            bodyFontColor: "#666",
            bodySpacing: 4,
            xPadding: 12,
            mode: "nearest",
            intersect: 0,
            position: "nearest",
          },
          hover: {
            animationDuration: 0,
          },
          animation: {
            duration: 0,
            onComplete: function () {
              var chartInstance = this.chart,
                ctx = chartInstance.ctx;

              ctx.font = Chart.helpers.fontString(
                Chart.defaults.global.defaultFontSize,
                Chart.defaults.global.defaultFontStyle,
                Chart.defaults.global.defaultFontFamily
              );
              ctx.textAlign = "center";
              ctx.textBaseline = "bottom";
              var gradient = ctx.createLinearGradient(0, 0, chartInstance.width, 0);
              gradient.addColorStop("0.5", "grey");
              ctx.fillStyle = gradient;

              chartInstance.data.datasets.forEach(function (dataset, i) {
                var meta = chartInstance.controller.getDatasetMeta(i);
                if (screen.width > 550)
                  meta.data.forEach(function (bar, index) {
                    var data = dataset.data[index].toLocaleString();
                    ctx.fillText(data, bar._model.x, bar._model.y - 3);
                  });
              });
            },
          },
        },
      },
      barChartProvenance: {
        chartData: {
          labels: [],
          datasets: [
            {
              label: "PROVENANCE",
              fill: true,
              backgroundColor: "#DD4E01",
              hoverBackgroundColor: "#DD4E01",
              borderColor: "#DD4E01",
              borderWidth: 2,
              borderDash: [],

              borderDashOffset: 0,
              data: [],
            },
          ],
        },
        extraOptions: {
          ...chartConfigs.barChartOptionsGradient,
          legend: {
            display: true,
            position: "bottom",
            align: "center",
            labels: {
              boxWidth: 15,
              fontSize: 12,
            },
          },
          tooltips: {
            backgroundColor: "#f5f5f5",
            titleFontColor: "#333",
            bodyFontColor: "#666",
            bodySpacing: 4,
            xPadding: 12,
            mode: "nearest",
            intersect: 0,
            position: "nearest",
          },
          hover: {
            animationDuration: 0,
          },
          animation: {
            duration: 0,
            onComplete: function () {
              var chartInstance = this.chart,
                ctx = chartInstance.ctx;

              ctx.font = Chart.helpers.fontString(
                Chart.defaults.global.defaultFontSize,
                Chart.defaults.global.defaultFontStyle,
                Chart.defaults.global.defaultFontFamily
              );
              ctx.textAlign = "center";
              ctx.textBaseline = "bottom";
              var gradient = ctx.createLinearGradient(0, 0, chartInstance.width, 0);
              gradient.addColorStop("0.5", "grey");
              ctx.fillStyle = gradient;

              chartInstance.data.datasets.forEach(function (dataset, i) {
                var meta = chartInstance.controller.getDatasetMeta(i);
                if (screen.width > 550)
                  meta.data.forEach(function (bar, index) {
                    var data = dataset.data[index].toLocaleString();
                    ctx.fillText(data, bar._model.x, bar._model.y - 3);
                  });
              });
            },
          },
        },
      },
      pagination: {
        perPage: 20,
        currentPage: 1,
        perPageOptions: [20, 50, 100, 200],
        total: 0,
      },

      dataChartVolume_by_user_traited_demande: null,
      dataChartVolume_by_demandes_status: null,
      dataChartVolume_by_source: null,

      dataChartVolume_by_provenance: null,
      selectedMonth: "Tous",
      datatwelveMonthsSource: null,
      datatwelveMonthsStatus: null,
      unsubscribeContacts: null,
      datatwelveMonthsUserTraitement: null,

      pieChart: {
        chartData: {},
        extraOptions: {
          legend: {
            display: true,
            position: "bottom",
            align: "center",
            labels: {
              boxWidth: 15,
              fontSize: 12,
            },
          },
          cutoutPercentage: 60,
          tooltips: {
            callbacks: {
              title: this.SetPieChartTitle,
              label: function (tooltipItem, data) {
                return data["datasets"][0]["data"][tooltipItem["index"]]
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, " ");
              },
              afterLabel: function (tooltipItem, data) {
                var dataset = data["datasets"][0];
                var total = dataset["data"].reduce((acc, curr) => acc + curr, 0);
                var value = dataset["data"][tooltipItem["index"]];
                var percent = ((value / total) * 100).toFixed(1);
                return "(" + percent + "%)";
              },
            },
            backgroundColor: "#FFF",
            titleFontSize: 16,
            titleFontColor: "#0066ff",
            bodyFontColor: "#000",
            bodyFontSize: 14,
            displayColors: false,
          },
        },
        provenances: [], // Initialisez provenances avec un tableau vide par défaut
        provolumes: {},
        provenancedata: [],
        user_traited_demande_labels: {},
        user_traited_demande_volume: {},
        volumeBySource: {},
        sourceLabels: {},
        sourceVolumes: {},
        statuses: {},
        statusVolumes: {},
        monthlySourceLabels: {},
        monthlySourceVolumes: {},
        monthlyStatuses: {},
        monthlyStatusVolumes: {},
      },
      cumulativeResult: [],
      objetRecherche: {},
    };
  },
  computed: {
    computedProvenancedata() {
      console.log("computedProvenancedata");
      if (!this.originalProvenancedata || this.originalProvenancedata.length === 0) {
        console.log("originalProvenancedata is empty or not initialized.");
        this.originalProvenancedata = this.provenancedata.slice();
        console.log("Initialized originalProvenancedata:", this.originalProvenancedata);
      }

      if (this.vartest === "all") {
        this.provenancedata = this.originalProvenancedata;
      } else if (this.vartest === "down") {
        this.provenancedata = this.originalProvenancedata.filter(
          (item) => item.volume < 150
        );
      } else if (this.vartest === "top") {
        this.provenancedata = this.originalProvenancedata.filter(
          (item) => item.volume > 150
        );
      }
      console.log("Filtered provenancedata:", this.provenancedata);

      this.pagination.total = this.provenancedata.length;

      return this.provenancedata.slice(this.from, this.to);
    },
    from() {
      return (this.pagination.currentPage - 1) * this.pagination.perPage;
    },
    to() {
      return Math.min(
        this.pagination.currentPage * this.pagination.perPage,
        this.pagination.total
      );
    },
    total() {
      return this.provenancedata.length;
    },
  },

  mounted() {
    this.selectedMonth = "Tous";
    this.filterByMonth();
    this.provenancedata = [...this.originalProvenancedata];
  },
  methods: {

    filterByMonth() {
      if (this.selectedMonth === "Tous") {
        this.updatePieChart(
          this.datatwelveMonthsSource,
          this.datatwelveMonthsStatus,
          this.datatwelveMonthsUserTraitement,
          false,
          [],
          [],
          []
        );
      } else {
        const contactsThisMonthSource = this.datatwelveMonthsSource.filter((contact) => {
          if (contact.demande_date) {
            const monthYear = moment(contact.demande_date).format("MM/YYYY");
            return monthYear === this.selectedMonth;
          }
          return false;
        });

        const contactsThisMonthStatus = this.datatwelveMonthsStatus.filter((contact) => {
          if (contact.demande_date) {
            const monthYear = moment(contact.demande_date).format("MM/YYYY");
            return monthYear === this.selectedMonth;
          }
          return false;
        });
        const contactsTraitement = this.datatwelveMonthsUserTraitement.filter(
          (contact) => {
            if (contact.user_traited_demande) {
              const monthYear = moment(contact.demande_date).format("MM/YYYY");
              return monthYear === this.selectedMonth;
            }
            return false;
          }
        );

        this.updatePieChart(
          contactsThisMonthSource,
          contactsThisMonthStatus,
          false,
          contactsTraitement
        );
      }
    },
    updatePieChart(contacts, contacts2, isAllOption, contactsTraitement) {
      const data = this.generateChartData(contacts, contacts2, contactsTraitement);

      this.dataChartVolume_by_source = {
        labels: data.sources,
        datasets: [
          {
            label: "",
            pointRadius: 0,
            pointHoverRadius: 0,
            backgroundColor: [
              "#1B61F7",
              "#DD4E01",
              "#088A08",
              "#ff0040",
              "#772e7f",
              "#fa7a47",
              "#9143df",
              "#dde02e",
              "#75ff42",
              "#FF0000",
              "#000",
              "#ffffa3",
              "#ffa6a3",
              "#b3908f",
              "#47fa80",
              "#601010",
              "#01FF70",
              "#85144b",
              "#F012BE",
              "#3D9970",
              "#111111",
              "#AAAAAA",
              "#FFDADA",
            ],
            borderWidth: 0,
            data: data.volumes,
          },
        ],
      };

      this.dataChartVolume_by_demandes_status = {
        labels: data.statuses,
        datasets: [
          {
            label: "",
            pointRadius: 0,
            pointHoverRadius: 0,
            backgroundColor: [
              "#1B61F7",
              "#DD4E01",
              "#088A08",
              "#ff0040",
              "#772e7f",
              "#fa7a47",
              "#9143df",
              "#dde02e",
              "#75ff42",
              "#FF0000",
              "#000",
              "#ffffa3",
              "#ffa6a3",
              "#b3908f",
              "#47fa80",
              "#601010",
              "#01FF70",
              "#85144b",
              "#F012BE",
              "#3D9970",
              "#111111",
              "#AAAAAA",
              "#FFDADA",
            ],
            borderWidth: 0,
            data: data.statusVolumes,
          },
        ],
      };

      this.dataChartVolume_by_user_traited_demande = {
        labels: data.user_traited_demande_labels,
        datasets: [
          {
            label: "",
            pointRadius: 0,
            pointHoverRadius: 0,
            backgroundColor: [
              "#1B61F7",
              "#DD4E01",
              "#088A08",
              "#ff0040",
              "#772e7f",
              "#fa7a47",
              "#9143df",
              "#dde02e",
              "#75ff42",
              "#FF0000",
              "#000",
              "#ffffa3",
              "#ffa6a3",
              "#b3908f",
              "#47fa80",
              "#601010",
              "#01FF70",
              "#85144b",
              "#F012BE",
              "#3D9970",
              "#111111",
              "#AAAAAA",
              "#FFDADA",
            ],
            borderWidth: 0,
            data: data.user_traited_demande_volume,
          },
        ],
      };
    },
    generateChartData(contacts, contacts2, contactsTraitement) {
      const volumeBySource = {};
      const volumeByStatus = {};
      const volumeByusertraitedemande = {};

      const updateVolumeBySource = (contact) => {
        const source = contact.from_source.toUpperCase();
        volumeBySource[source] = (volumeBySource[source] || 0) + 1;
      };

      const updateVolumeByStatus = (contact) => {
        const status = contact.demande_status.toUpperCase();
        volumeByStatus[status] = (volumeByStatus[status] || 0) + 1;
      };
      const updateVolumeByTraitement = (contact) => {
        const traitement = contact.user_traited_demande.toUpperCase();
        volumeByusertraitedemande[traitement] =
          (volumeByusertraitedemande[traitement] || 0) + 1;
      };

      if (contacts && Array.isArray(contacts)) {
        contacts.forEach((contact) => {
          updateVolumeBySource(contact);
        });
      }

      if (contacts2 && Array.isArray(contacts2)) {
        contacts2.forEach((contact) => {
          updateVolumeByStatus(contact);
        });
      }

      if (contactsTraitement && Array.isArray(contactsTraitement)) {
        contactsTraitement.forEach((contact) => {
          updateVolumeByTraitement(contact);
        });
      }

      const sources = Object.keys(volumeBySource).map((source) => source.toUpperCase());

      const volumes = Object.values(volumeBySource);

      const statuses = Object.keys(volumeByStatus).map((status) => status.toUpperCase());

      const statusVolumes = Object.values(volumeByStatus);

      const user_traited_demande_labels = Object.keys(
        volumeByusertraitedemande
      ).map((traitement) => traitement.toUpperCase());

      const user_traited_demande_volume = Object.values(volumeByusertraitedemande);

      return {
        sources,
        volumes,
        statuses,
        statusVolumes,
        user_traited_demande_labels,
        user_traited_demande_volume,
      };
    },

    TwelveMonths() {
      let currentDate = new Date();
      let months = [];
      let uniqueMonths = new Set();
      for (let i = 0; i < 12; i++) {
        let month = currentDate.getMonth() + 1;
        let year = currentDate.getFullYear();
        let monthString = month.toString().padStart(2, "0");
        let monthYear = `${monthString}/${year}`;
        if (!uniqueMonths.has(monthYear)) {
          months.push(monthYear);
          uniqueMonths.add(monthYear);
        }
        currentDate.setMonth(currentDate.getMonth() - 1);
      }
      return months.reverse();
    },
    SetPieChartTitle: function (tooltipItem, data) {
      let label = data["labels"][tooltipItem[0]["index"]];
      if (
        data.label === "Volume par Source" &&
        this.sourcesStock &&
        this.sourcesStock.data
      ) {
        let find = this.sourcesStock.data.getUnsubscribeContacts.find(
          (x) => x.source.toUpperCase() == label
        );
        if (find) {
          return label + " ( " + find.source + " )";
        }
      } else if (
        data.label === "Volume par Statut" &&
        this.StatusStock &&
        this.StatusStock.data
      ) {
        let find = this.StatusStock.data.getUnsubscribeContacts.find(
          (x) => x.demande_status.toUpperCase() == label
        );
        if (find) {
          return label + " ( " + find.demande_status + " )";
        }
      }
      return label;
    },
    isMobile() {
      if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        )
      ) {
        return true;
      } else {
        return false;
      }
    },
  },
};
</script>
