<template>
  <div class="content">
    <h3>LES CONTACTS DÉSABONNÉS</h3>
    <div class="col-md-12 float-right">
      <div class="btn-group btn-group-toggle float-right" data-toggle="buttons">

        <base-button
          :disabled="$apollo.queries.getUnsubscribeContacts.loading"
          round
          class="btn btn-round btn-just-icon btn-warning mb-2"
          @click.native="showAddLigne = true"
        >AJOUTER UNE LIGNE</base-button>


        <base-button
          :disabled="$apollo.queries.getUnsubscribeContacts.loading"
          round
          class="btn btn-round btn-just-icon btn-warning mb-2"
          @click.native="refreshQuery()"
        >
          <i class="fas fa-sync-alt"></i>

        </base-button>


      </div>
    </div>
    <card>
      <div class="row">
        <div class="col-xl-1 col-sm-6">
          <base-input>
            <el-select
              class="select-primary pagination-select"
              v-model="pagination.perPage"
              placeholder="Per page"
            >
              <el-option
                class="select-primary"
                v-for="item in pagination.perPageOptions"
                :key="item"
                :label="item"
                :value="item"
              >
              </el-option>
            </el-select>
          </base-input>
        </div>
        <div class="col-xl-8 col-sm-6">
          <label style="margin-top: 10px; font-size: 0.75rem">{{ $t("labels.Datatable.NbrLinePerPage").toUpperCase() }}</label>
        </div>
        <base-input class="col-xl-3 col-sm-6">
          <el-input
            type="search"
            class="mb-3 search-input"
            clearable
            prefix-icon="el-icon-search"
            :placeholder="$t('buttons.BtnSearch2').toUpperCase()"
            v-model="searchQuery"
            aria-controls="datatables"
          >
          </el-input>
        </base-input>
      </div>
      <el-table :data="searchUnsubscribeContacts.slice(from, to)" v-loading="$apollo.queries.getUnsubscribeContacts.loading" row-class-name="booking-table-rows" class="customtable_cmd">
        <el-table-column type="expand">
          <template scope="scope">
            <template v-if="scope.row.source">
            <div class="row">


              <div class="col-md-6">
                <p class="">
                  La liste des  Sources : <br>
                  <span class="text-uppercase" v-html="scope.row.source.replace(/;/g, '<br>')"></span>

                </p>

              </div>

              <div class="col-md-6">
                <p class="">
                  La liste des destinataires  : <br>
                  <span class="text-uppercase" v-html="scope.row.traces.replace(/;/g, '<br>')"></span>

                </p>

              </div>

            </div>

             </template>
          </template>
        </el-table-column>



        <el-table-column
          prop='from_source'
          label="L'origine de la demande"
           show-overflow-tooltip
          sortable
        >

          <template scope="scope">

            <b>
               {{scope.row.from_source}}
            </b>

          </template>


        </el-table-column>

        <el-table-column
          prop='email'
          label='email'
          minWidth='100'
          show-overflow-tooltip
          sortable
        >
          <template scope="scope">

            <b>

            </b>
            {{scope.row.email}} <br>   {{scope.row.telephone}}
          </template>

        </el-table-column>

        <el-table-column
          prop='nom'
          label='nom'
          show-overflow-tooltip
          sortable
        >
          <template scope="scope">

             <span v-if="!scope.row.nom">---</span>
             {{scope.row.nom}}   {{scope.row.prenom}}
           </template>
        </el-table-column>


        <el-table-column
          prop='demande_date'
          label='date de la demande'
          show-overflow-tooltip
          sortable
        >
          <template scope="scope">

            {{scope.row.demande_date||'---'}}
          </template>
        </el-table-column>

        <el-table-column
          prop='user_traited_demande'
          label='Personne ayant traiter la demande'
          show-overflow-tooltip
          sortable
        >

          <template scope="scope">

            {{scope.row.user_traited_demande||'---'}}
          </template>
        </el-table-column>

        <el-table-column
          prop='retour_demandeur_date'
          label='Date retour au demandeur'
          show-overflow-tooltip
          sortable
        >

          <template scope="scope">

            {{scope.row.retour_demandeur_date||'---'}}
          </template>

        </el-table-column>


        <el-table-column
          prop='demande_status'
          label='status de la demande'
          show-overflow-tooltip
          sortable
        >
        </el-table-column>

        <el-table-column
          prop='demande_type'
          label='type demande'
          show-overflow-tooltip
          sortable
        >
          <template scope="scope">

            <span v-if="scope.row.demande_type === 'UR'" class="badge badge-danger">
              DÉSINSCRIPTION <br> DES BASES </span>
            <span v-if="scope.row.demande_type === 'IR'" class="badge badge-success">DEMANDE <brD’INFORMATION OPTIN</span>


          </template>

        </el-table-column>

        <el-table-column
          label=''
        >
          <template scope="scope">
            <base-button
              @click="openEdit(scope.row)"
              class="btn-link"
              type="info"
              size="sm"
              icon
            >
              <i class="fas fa-edit icon-large"></i>
            </base-button>
          </template>
        </el-table-column>

      </el-table>
      <div
        class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
      >
        <div class="">
          <p class="card-category">
            {{ $t('labels.Datatable.Showing') }} {{ from + 1 }}  {{ $t('labels.Datatable.to') }} {{ to }} {{ $t('labels.Datatable.of') }} {{ total }} {{ $t('labels.Datatable.entries') }}
          </p>
        </div>
        <base-pagination
          type="warning"
          class="pagination-no-border"
          v-model="pagination.currentPage"
          :per-page="pagination.perPage"
          :total="total"
        >
        </base-pagination>
      </div>
    </card>

    <!-- Mondal Add New Ligne -->

    <modal_selfEvent
      :show.sync="showAddLigne"
      body-classes="p-0"
      class="modal-listbase"
    >
      <card type="secondary"
        header-classes="bg-white pb-6"
        body-classes="px-lg-6 py-lg-6"
        class="border-0 mb-0"
      >
        <el-tooltip
          content="Quitter"
          effect="light"
          :open-delay="300"
          placement="top"
        >

          <base-button
            @click.native="showAddLigne = false"
            class="btn-link float-right"
            type="danger"
            size="sm"
            icon
          >
            <i class="tim-icons icon-simple-remove icon-large"></i>
          </base-button>
        </el-tooltip>

        <br>
        <br>

        <div class="row">
          <base-input
            :error="getError('from_source')"
            label="L'origine de la demande"
                     class=" col-md-12 mb-2"   >
            <el-select
              class="select-primary"
              v-model="LeadUnsubscrib.from_source"
              clearable
              label="L'origine de la demande"
              :error="getError('from_source')"
              name="from_source"
              v-validate="'required'"
            >
              <el-option class="select-primary" label="DPO" value="DPO"></el-option>
              <el-option class="select-primary" label="Email" value="Email"></el-option>
              <el-option class="select-primary" label="Formulaire Mongustave" value="Formulaire Mongustave"></el-option>
              <el-option class="select-primary" label="Partenaires" value="Partenaires"></el-option>
              <el-option class="select-primary" label="Teletech" value="Teletech"></el-option>
              <el-option class="select-primary" label="Autre" value="Autre"></el-option>

            </el-select>
          </base-input>


        </div>

        <div class="row">


          <base-input
            label="email" placeholder="EMAIL"
            class="col-md-6"
            name="email"
            v-model="LeadUnsubscrib.email"
            :error="getError('email')"
            v-validate="ValidateEmail"
            :disabled="enable_edit"
          >
          </base-input>
          <base-input
            label="telephone" placeholder="TELEPHONE"
            class="col-md-6"
            name="telephone"
            v-model="LeadUnsubscrib.telephone"
            :error="getError('telephone')"
            v-validate="ValidatePhone"
            :disabled="enable_edit"
          >
          </base-input>

          <base-input
            label="nom" placeholder="NOM"
            class="col-md-6"
            name="nom"
            v-model="LeadUnsubscrib.nom"
            :error="getError('nom')"
           >
          </base-input>
          <base-input
            label="prenom" placeholder="PRENOM"
            class="col-md-6"
            name="prenom"
            v-model="LeadUnsubscrib.prenom"
            :error="getError('prenom')"
           >
          </base-input>

          <base-input label="civilite" class="col-md-6">
            <el-select
              class="select-primary"
              v-model="LeadUnsubscrib.civilite"
              placeholder="CIVILITE"
            >
              <el-option class="select-primary" label="MONSIEUR" value="Mr"></el-option>
              <el-option class="select-primary" label="MADAME" value="Mme"></el-option>
            </el-select>
          </base-input>
          <base-input
            label="profession" placeholder="PROFESSION"
            class="col-md-6"
            name="profession"
            v-model="LeadUnsubscrib.profession"
          >
          </base-input>


          <base-input
            label="cp" placeholder="CP"
            class="col-md-6"
            name="cp"
            v-model="LeadUnsubscrib.cp"
          >
          </base-input>
          <base-input
            label="ville" placeholder="VILLE"
            class="col-md-6"
            name="ville"
            v-model="LeadUnsubscrib.ville"
          >
          </base-input>

          <base-input label="date naissance" class="col-md-6">
            <el-date-picker
              name="date naissance"
              v-model="LeadUnsubscrib.date_naissance"
              type="date"
              value-format="yyyy-MM-dd"
              placeholder="DATE NAISSANCE"
            ></el-date-picker>
          </base-input>
          <base-input
            label="source" placeholder="SOURCE"
            class="col-md-6"
            name="source"
            v-model="LeadUnsubscrib.source"
            v-if="!enable_edit"
          >
          </base-input>

          <!-- <base-input label="date collecte" class="col-md-6">
            <el-date-picker
              name="date collecte"
              v-validate="'required'"
              v-model="LeadUnsubscrib.date_collecte"
              type="date"
              value-format="yyyy-MM-dd"
              placeholder="DATE COLLECTE"
            ></el-date-picker>
          </base-input> -->
          <base-input
            label="provenance" placeholder="PROVENANCE"
            class="col-md-6"
            name="provenance"
            v-model="LeadUnsubscrib.provenance"
            v-if="!enable_edit"
          >
          </base-input>
          <base-input label="date de la demande" class="col-md-6">
            <el-date-picker
              name="date de la demande"
              v-model="LeadUnsubscrib.demande_date"
              type="date"
              value-format="yyyy-MM-dd"
              placeholder="DATE DE LA DEMANDE"
            ></el-date-picker>
          </base-input>

          <base-input
            label="Personne ayant traiter la demande" placeholder="PERSONNE AYANT TRAITER LA DEMANDE"
            class="col-md-6"
            name="Personne ayant traiter la demande"
            v-model="LeadUnsubscrib.user_traited_demande"
          >
          </base-input>
          <base-input label="Date retour au demandeur" class="col-md-6">
            <el-date-picker
              name="Date retour au demandeur"
              v-model="LeadUnsubscrib.retour_demandeur_date"
              type="date"
              value-format="yyyy-MM-dd"
              placeholder="DATE RETOUR AU DEMANDEUR"
            ></el-date-picker>
          </base-input>

          <base-input label="status de la demande" class="col-md-6">
            <el-select
              class="select-primary"
              v-model="LeadUnsubscrib.demande_status"
              placeholder="STATUS DE LA DEMANDE"
            >
              <el-option class="select-primary" label="EN ATTENTE" value="en attente"></el-option>
              <el-option class="select-primary" label="EN COURS" value="en cours"></el-option>
              <el-option class="select-primary" label="TRAITÉ" value="traité"></el-option>
            </el-select>
          </base-input>

          <base-input label="type de la demande" class="col-md-6">
            <el-select
              class="select-primary"
              v-model="LeadUnsubscrib.demande_type"
              placeholder="TYPE DE LA DEMANDE"
            >
              <el-option class="select-primary" label="DÉSINSCRIPTION DES BASES" value="UR"></el-option>
              <el-option class="select-primary" label="DEMANDE D’INFORMATION OPTIN" value="IR"></el-option>
            </el-select>
          </base-input>

          <base-input label="type de la demande" class="col-md-12">
              <textarea class="form-control " rows="3" v-model="LeadUnsubscrib.comment"></textarea>
            </base-input>

        </div>

        <div class="row">


        </div>
        <center>
          <template v-if="!loaderAddLigne">
            <base-button
              v-if="enable_edit"
              type="info"
              @click="EditLigne"
              >{{ $t("buttons.BtnModify").toUpperCase() }}
            </base-button>
            <base-button
              v-else
              type="info"
              @click="AddLigne"
              >{{ $t("buttons.BtnAdd").toUpperCase() }}
            </base-button>
          </template>
          <base-button
            v-else
            type="info"
            loading
            >
          </base-button>
        </center>

      </card>
    </modal_selfEvent>
  </div>
</template>

<script>
import { Table, TableColumn, Select, Option, DatePicker } from 'element-ui'
import { BasePagination, BaseSwitch, BaseDropdown } from 'src/components';
import Modal_selfEvent from 'src/components/Modal_selfEvent'
import Fuse from 'fuse.js';
import gql from 'graphql-tag'
import moment from 'moment'
import { BaseRadio } from 'src/components/index';

export default {
  apollo: {
    getUnsubscribeContacts: {
      client: 'MARKET',
      query: gql` query {
        getUnsubscribeContacts {
          id
          ids
          source
          nom
          prenom
          civilite
          email
          telephone
          cp
          traces
          ville
          profession
          date_naissance
          date_collecte
          provenance
          from_source
          demande_date
          user_traited_demande
          retour_demandeur_date
          demande_status
          demande_type
          created_at
          updated_at
          comment
        }
      }`,
      result({ data, loader, networkStatus }) {
        this.ListUnsubscribeContacts = this.searchUnsubscribeContacts = data.getUnsubscribeContacts
        this.fuseSearch = new Fuse(this.ListUnsubscribeContacts, this.options);
        console.log(data.getUnsubscribeContacts)
      }
    }
  },
  watch: {
    searchQuery(value) {
      let result = this.ListUnsubscribeContacts;
      if (value !== '' && this.fuseSearch) {
        result = this.fuseSearch.search(this.searchQuery);
      }
      this.searchUnsubscribeContacts = result;
    },
    showAddLigne(value) {
      if (!value) {
        this.clearFiled()
        this.enable_edit = false
      }
    }
  },
  components: {
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [DatePicker.name]: DatePicker,
    BasePagination,BaseRadio,
    Modal_selfEvent
  },
  data() {
    return {
      searchUnsubscribeContacts: [],
      ListUnsubscribeContacts: [],
      fuseSearch: null,
      showAddLigne: false,
      loaderAddLigne: false,
      enable_edit: false,
      searchQuery: '',
      pagination: {
        perPage: 50,
        currentPage: 1,
        perPageOptions: [50, 100, 250, 500],
        total: 0
      },
      options : {
        threshold: 0.2,
        keys: [
          "nom",
          "prenom",
          "email",
          "telephone",
          "ville",
          "provenance",
          "source"
        ]
      },
      LeadUnsubscrib: {
        email: "",
        telephone: "",
        nom: "",
        prenom: "",
        civilite: "Mr",
        profession: "",
        cp: "",
        ville: "",
        comment: "",
        source: "",
        date_naissance: "",
        // date_collecte: "",
        provenance: "",
        from_source: "",
        demande_date: moment().format('YYYY-MM-DD'),
        user_traited_demande: "",
        retour_demandeur_date: "",
        demande_status: "en attente",
         demande_type: "UR"
      },
      loaderAddRepport: false
    }
  },

  computed: {
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    ValidatePhone() {
      if(this.LeadUnsubscrib.email)
      {
        return '';
      }
      return  'required|regex:^[0-9]{10}';
    },
    ValidateEmail() {
      if(this.LeadUnsubscrib.telephone)
      {
        return '';
      }
      return  'required|email';
    },
    total() {
      return this.searchUnsubscribeContacts.length
    }
  },
  methods: {
    getError(fieldName) {
      return this.errors.first(fieldName)
    },
    refreshQuery() {
      this.$apollo.queries.getUnsubscribeContacts.refetch();
    },
    openEdit(row) {
      this.enable_edit = true
      this.showAddLigne = true

      this.LeadUnsubscrib.email = row.email
      this.LeadUnsubscrib.telephone = row.telephone
      this.LeadUnsubscrib.nom = row.nom
      this.LeadUnsubscrib.prenom = row.prenom
      this.LeadUnsubscrib.civilite = row.civilite
      this.LeadUnsubscrib.profession = row.profession
      this.LeadUnsubscrib.cp = row.cp
      this.LeadUnsubscrib.ville = row.ville
      this.LeadUnsubscrib.source = row.source
      this.LeadUnsubscrib.date_naissance = row.date_naissance
      this.LeadUnsubscrib.provenance = row.provenance
      this.LeadUnsubscrib.demande_date = row.demande_date
      this.LeadUnsubscrib.user_traited_demande = row.user_traited_demande
      this.LeadUnsubscrib.retour_demandeur_date = row.retour_demandeur_date
      this.LeadUnsubscrib.demande_status = row.demande_status
      this.LeadUnsubscrib.id = row.id
      this.LeadUnsubscrib.from_source = row.from_source
      this.LeadUnsubscrib.ids = row.ids
      this.LeadUnsubscrib.comment = row.comment
    },
    async EditLigne() {
      let validate
      await this.$validator.validateAll().then(isValid => {
        validate = isValid
      })

      if (!validate)
        return

        this.loaderAddLigne = true

        this.$apollo.query({
          client: 'MARKET',
          query: gql`query (
              $id: ID
              $ids: String
              # $source: String
              $nom: String
              $prenom: String
              $civilite: String
              $email: String
              $telephone: String
              $cp: String
              $ville: String
              $profession: String
              $date_naissance: Date
              $from_source: String
              $demande_date: String
              $user_traited_demande: String
              $retour_demandeur_date: String
              $demande_status: String
              $demande_type: String
              $comment: String
            ) {
              updateUnsubscribeContact (
              id: $id,
              ids: $ids,
              # source: $source,
              nom: $nom,
              prenom: $prenom,
              civilite: $civilite,
              email: $email,
              telephone: $telephone,
              cp: $cp,
              ville: $ville,
              profession: $profession,
              date_naissance: $date_naissance,
              # provenance: $provenance,
               from_source: $from_source,
              demande_date: $demande_date,
              user_traited_demande: $user_traited_demande,
              retour_demandeur_date: $retour_demandeur_date,
              demande_status: $demande_status,
              demande_type: $demande_type,
              comment: $comment
            ) {
              state
            }
          }`,
          variables: {
            id: this.LeadUnsubscrib.id,
            ids: this.LeadUnsubscrib.ids,
            // source: this.LeadUnsubscrib.source,
            nom: this.LeadUnsubscrib.nom,
            prenom: this.LeadUnsubscrib.prenom,
            civilite: this.LeadUnsubscrib.civilite,
            email: this.LeadUnsubscrib.email,
            telephone: this.LeadUnsubscrib.telephone,
            cp: this.LeadUnsubscrib.cp,
            ville: this.LeadUnsubscrib.ville,
            profession: this.LeadUnsubscrib.profession,
            from_source: this.LeadUnsubscrib.from_source,
            date_naissance: this.LeadUnsubscrib.date_naissance,
            // provenance: this.LeadUnsubscrib.provenance,
            demande_date: this.LeadUnsubscrib.demande_date,
            user_traited_demande: this.LeadUnsubscrib.user_traited_demande,
            retour_demandeur_date: this.LeadUnsubscrib.retour_demandeur_date,
            demande_status: this.LeadUnsubscrib.demande_status,
            demande_type: this.LeadUnsubscrib.demande_type,
            comment: this.LeadUnsubscrib.comment
          },
        }).then(response => {
          if (response.data.updateUnsubscribeContact.state) {
            this.$apollo.queries.getUnsubscribeContacts.refetch()
            this.$notify({type: 'success', duration: 5000, message: "ligne modifié avec succès" })
            this.clearFiled()
            this.showAddLigne = false
          }
          else {
            this.$notify({type: 'warning', message: this.$t("apiresponse.errorupdate") })
          }
          this.loaderAddLigne = false
        }).catch(error => {
          this.$notify({type: 'warning', message: this.$t("apiresponse.errorupdate") })
          this.loaderAddLigne = false
        })

    },
    async AddLigne() {
      let validate
      await this.$validator.validateAll().then(isValid => {
        validate = isValid
      })
      if (!validate)
        return

      this.loaderAddLigne = true

      this.$apollo.query({
        client: 'MARKET',
        query: gql`query (
            $source: String
            $nom: String
            $prenom: String
            $civilite: String
            $email: String
            $telephone: String
            $cp: String
            $ville: String
            $profession: String
            $date_naissance: Date
            $provenance: String
            $from_source: String
            $demande_date: String
            $user_traited_demande: String
            $retour_demandeur_date: String
            $demande_status: String
            $demande_type: String
            $comment: String
          ) {
          addUnsubscribeContact (
            source: $source,
            nom: $nom,
            prenom: $prenom,
            civilite: $civilite,
            email: $email,
            telephone: $telephone,
            cp: $cp,
            ville: $ville,
            profession: $profession,
            date_naissance: $date_naissance,
            provenance: $provenance,
            from_source: $from_source,
            demande_date: $demande_date,
            user_traited_demande: $user_traited_demande,
            retour_demandeur_date: $retour_demandeur_date,
            demande_status: $demande_status,
            demande_type: $demande_type,
            comment: $comment
          ) {
            id
          }
        }`,
        variables: {
          source: this.LeadUnsubscrib.source,
          nom: this.LeadUnsubscrib.nom,
          prenom: this.LeadUnsubscrib.prenom,
          civilite: this.LeadUnsubscrib.civilite,
          email: this.LeadUnsubscrib.email,
          telephone: this.LeadUnsubscrib.telephone,
          cp: this.LeadUnsubscrib.cp,
          ville: this.LeadUnsubscrib.ville,
          profession: this.LeadUnsubscrib.profession,
          date_naissance: this.LeadUnsubscrib.date_naissance,
          provenance: this.LeadUnsubscrib.provenance,
          from_source: this.LeadUnsubscrib.from_source,
          demande_date: this.LeadUnsubscrib.demande_date,
          user_traited_demande: this.LeadUnsubscrib.user_traited_demande,
          retour_demandeur_date: this.LeadUnsubscrib.retour_demandeur_date,
          demande_status: this.LeadUnsubscrib.demande_status,
          demande_type: this.LeadUnsubscrib.demande_type,
          comment: this.LeadUnsubscrib.comment
        },
      }).then(response => {
        if (response.data.addUnsubscribeContact.id) {
          this.$apollo.queries.getUnsubscribeContacts.refetch()
          this.$notify({type: 'success', duration: 5000, message: "ligne crée avec succès" })
          this.clearFiled()
          this.showAddLigne = false
        }
        else {
          this.$notify({type: 'warning', message: this.$t("apiresponse.errorcreation") })
        }
        this.loaderAddLigne = false
      }).catch(error => {
        this.$notify({type: 'warning', message: this.$t("apiresponse.errorcreation") })
        this.loaderAddLigne = false
      })
    },
    clearFiled() {
      this.LeadUnsubscrib.email = ""
      this.LeadUnsubscrib.telephone = ""
      this.LeadUnsubscrib.nom = ""
      this.LeadUnsubscrib.prenom = "",
      this.LeadUnsubscrib.civilite = "Mr"
      this.LeadUnsubscrib.profession = ""
      this.LeadUnsubscrib.cp = ""
      this.LeadUnsubscrib.comment = ""
      this.LeadUnsubscrib.ville = ""
      this.LeadUnsubscrib.source = ""
      this.LeadUnsubscrib.date_naissance = ""
      this.LeadUnsubscrib.provenance = ""
      this.LeadUnsubscrib.from_source = "AUTER"
      this.LeadUnsubscrib.demande_date = moment().format('YYYY-MM-DD')
      this.LeadUnsubscrib.user_traited_demande = ""
      this.LeadUnsubscrib.retour_demandeur_date = ""
      this.LeadUnsubscrib.demande_status = "en attente"
      this.LeadUnsubscrib.demande_type = "UR"
    }
  }
}


</script>


<style>
  .booking-table-rows {
    text-transform: uppercase;
  }
  .customtable_cmd .cell {
    word-break: break-word;
  }
  .el-table .caret-wrapper {
    width: auto
  }
</style>
