<template>
  <div class="content">
    <h3>VUE SUR LE STOCK</h3>

    <div class="col-lg-12">
      <div class="card card-chart" data="orange">
        <div class="card-header">
          <div class="col-sm-6 text-left">
            <h2 class="card-title">VOLUME DISPONIBLE</h2>
            <h5 class="card-category" style="color:#000">
              {{ $t("Pages.dashboard.StatsWeek").toUpperCase() }}
            </h5>
          </div>
        </div>

        <div class="card-body">
          <base-button 
            v-if="$apollo.queries.stockDays.loading" 
            style="position: absolute;top: 60%;
              left: 50%;
              -moz-transform: translateX(-50%) translateY(-50%);
              -webkit-transform: translateX(-50%) translateY(-50%);
              transform: translateX(-50%) translateY(-50%);" 
            loading type="default">
          </base-button>
          <div class="chart-area">
            <bar-chart
              v-if="!$apollo.queries.stockDays.loading"
              style="height: 100%"
              :chart-data="barChartCa.chartData"
              :extra-options="barChartCa.extraOptions"
            >
            </bar-chart>
          </div>
        </div>

      </div>
    </div>

    <div class="col-md-12">
      <div class="card">
        <span slot="header" class="badge-pill badge-warning tab-title" style="font-weight: bold;">
          VOLUME DISPONIBLE PAR SOURCE (AUJOURD'HUI)
        </span>
        <br/>
        <base-button 
          v-if="$apollo.queries.sourcesStock.loading" 
          style="position: absolute;top: 60%;
            left: 50%;
            -moz-transform: translateX(-50%) translateY(-50%);
            -webkit-transform: translateX(-50%) translateY(-50%);
            transform: translateX(-50%) translateY(-50%);" 
          loading type="default">
        </base-button>
        <pie-chart
          :chart-data="dataChartVolume_by_source"
          :extra-options="pieChart.extraOptions"
          :height="isMobile() ? 600 : 120"
          v-if="!$apollo.queries.sourcesStock.loading"
        >
        </pie-chart>
      </div>
    </div>
  </div>
</template>

<script>
  import BarChart from "@/components/Charts/BarChart";
  import PieChart from 'src/components/Charts/PieChart';
  import * as chartConfigs from "@/components/Charts/config";
  import gql from "graphql-tag";
  import moment from 'moment'
  moment.locale(localStorage.getItem('lang') == "en" ? "en" : "fr");

  export default {
    apollo: {
      stockDays: {
        client: 'MARKET',
        query: gql `
          query {
            stockDays{
              day
              month
              stock
            }
          }
        `,
        result({ data, loader, networkStatus }) {
          let datasets = []
          let labels = []
          data.stockDays.forEach(element => {
            datasets.push(element.stock)
            labels.push(element.day + "/" + moment().month(element.month-1).format("MMMM"))
          });
          this.barChartCa.chartData.datasets[0].data = datasets
          this.barChartCa.chartData.labels = labels
        }
      },
      sourcesStock: {
        client: 'MARKET',
        query: gql `
          query {
            sourcesStock{
              source_id
              volume
              source_name
            }
          }
        `,
        result({ data, loader, networkStatus }) {
          let sources = data.sourcesStock.map(el => el.source_name.toUpperCase())
          let volumes = data.sourcesStock.map(el => el.volume)
          this.dataChartVolume_by_source = {
            labels: sources,
            datasets: [
              {
                label: '',
                pointRadius: 0,
                pointHoverRadius: 0,
                backgroundColor: ['#1B61F7', '#DD4E01', '#088A08', '#ff0040', '#772e7f', '#fa7a47', '#9143df', '#dde02e', '#75ff42', '#FF0000', '#000', '#ffffa3', '#ffa6a3', '#b3908f', '#47fa80', '#601010', "#01FF70", "#85144b", "#F012BE", "#3D9970", "#111111", "#AAAAAA", "#FFDADA" ],
                borderWidth: 0,
                data: volumes
              }
            ]
          }
        }
      },
    },
    components: {
      BarChart,
      PieChart
    },
    data() {
      return {
        dataChartVolume_by_source: null,
        barChartCa: {
          chartData: {
            labels: [],
            datasets: [
              {
                label: 'SMS',
                fill: true,
                backgroundColor: "#DD4E01",
                hoverBackgroundColor: "#DD4E01",
                borderColor: "#DD4E01",
                borderWidth: 2,
                borderDash: [],
                borderDashOffset: 0.0,
                data: []
              },
            ]
          },
          extraOptions: {
            ...chartConfigs.barChartOptionsGradient,
            legend: {
              display: true
            },
            tooltips: {
              backgroundColor: '#f5f5f5',
              titleFontColor: '#333',
              bodyFontColor: '#666',
              bodySpacing: 4,
              xPadding: 12,
              mode: 'nearest',
              intersect: 0,
              position: 'nearest'
            },
            "hover": {
              "animationDuration": 0
            },
            animation: {
              duration : 0,
              onComplete : function() {
                var chartInstance = this.chart,
                ctx = chartInstance.ctx;

                ctx.font = Chart.helpers.fontString(Chart.defaults.global.defaultFontSize, Chart.defaults.global.defaultFontStyle, Chart.defaults.global.defaultFontFamily);
                ctx.textAlign = 'center';
                ctx.textBaseline = 'bottom';
                var gradient = ctx.createLinearGradient(0, 0, chartInstance.width, 0);
                gradient.addColorStop("0.5", "grey");
                ctx.fillStyle = gradient;
                this.data.datasets.forEach(function(dataset, i) {
                  var meta = chartInstance.controller.getDatasetMeta(i);
                  if(screen.width > 550)
                    meta.data.forEach(function(bar, index) {
                      var data = dataset.data[index].toLocaleString();
                      ctx.fillText(data, bar._model.x, bar._model.y - 3);
                    });
                });
              }
            },
          }
        },
        pieChart: {
          chartData: {},
          extraOptions: {
            legend: {
              display: true,
              position: 'bottom',
              align: 'start',
              labels: {
                boxWidth: 15,
                fontSize: 12
              }
            },
            cutoutPercentage: 60,
            tooltips: {
              callbacks: {
                title: this.SetPieChartTitle,
                label: function(tooltipItem, data) {
                  return data['datasets'][0]['data'][tooltipItem['index']].toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
                },
                afterLabel: function(tooltipItem, data) {
                  var dataset = data['datasets'][0];
                  
                  var percent = ((dataset['data'][tooltipItem['index']] / dataset["_meta"][Object.keys(dataset["_meta"])[0]].total) * 100).toFixed(1);
                  return '(' + percent + '%)';
                }
              },
              backgroundColor: '#FFF',
              titleFontSize: 16,
              titleFontColor: '#0066ff',
              bodyFontColor: '#000',
              bodyFontSize: 14,
              displayColors: false
            }
          }
        },
      }
    },
    methods: {
      SetPieChartTitle: function(tooltipItem, data) {
        let label = data['labels'][tooltipItem[0]['index']]
        let find = this.sourcesStock.find(x => x.source_name.toUpperCase() == label)
        return data['labels'][tooltipItem[0]['index']] + " ( " + find.source_id + " )" ;
      },
      isMobile() {
        if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
          return true
        } else {
          return false
        }
      }
    }
  }
</script>